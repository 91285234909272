import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
  CommunicationMode,
  CustomerSizes,
  Engagement,
  EngagementState,
  EngagementStateType
} from '../../services/engagement';
import {CrmStructure} from '../../services/crm-service/crm-category';
import {CrmEmailStateType} from '../../services/crm-service/crm-email-state';
import {CheckListField} from '../../classes/checklist/CheckListField';
import {EngagementEvent} from '../../services/crm-service/engagement-event';
import {BrowsingHistory} from '../../services/crm-service/browsing-history';
import {Opportunity} from '../../services/crm-service/opportunity';
import {MatchedCustomer} from '../../services/crm-service/matched-customer';
import {TextMessage} from '../../classes/TextMessage';
import {combineLatest, Observable, of} from 'rxjs';
import {Size} from '../../classes/Size';
import {BrowserService} from '../../services/browser-service/browser.service';
import {IBrowser} from '../../services/browser-service/IBrowser';
import {map} from 'rxjs/operators';
import {CallType} from '../../enums/call-type.enum';
import {EngagementMode} from '../../enums/engagement-mode';
import {
  faChevronLeft,
  faChevronRight, faCirclePause, faCircleUp,
  faHeartCircleMinus, faPauseCircle,
} from '@fortawesome/free-solid-svg-icons';
import {Section} from "../visitor-information/visitor-information.component";
import {SiteVisitor} from "../../services/visitor-service/SiteVisitor";

@Component({
  selector: 'app-engagement-center-console',
  templateUrl: './engagement-center-console.component.html',
  styleUrls: ['./engagement-center-console.component.scss']
})
export class EngagementCenterConsoleComponent {

  public activeIndex: number = 0;

  private _engagement: Engagement;
  public browser: IBrowser;

  @Input() useCoBrowse = false;
  @Input() useSharing = false;
  @Input() useSecureSharing = false;
  @Input() canCoBrowse: boolean = false;
  @Input() canShare: boolean = false;
  @Input() canCoShare: boolean = false;
  @Input() canSecureShare: boolean = false;
  @Input() canAppView: boolean = false;
  @Input() canCoFormFillShare: boolean = false;


  @Input() set engagement(engagement: Engagement) {
    this._engagement = engagement;

    if (engagement) {
      this.browser = this.browserService.getBrowser(this.engagement.engagementId.toString());

      this.hasVideo = combineLatest([engagement.chatOnly, engagement.communicationMode])
                        .pipe(map(([chatOnly, commsMode]) =>
                                    (engagement.visitor.callType === CallType.WebRTC) && !chatOnly && (commsMode !== CommunicationMode.OFF)));

      this.showUserPanel = combineLatest([engagement.panelFullSize, engagement.engagementMode])
                  .pipe(map(([fullSize, mode]) => !fullSize || (mode == EngagementMode.UNKNOWN) || (mode == EngagementMode.BROWSER)));
    } else {
      this.hasVideo = of(false);
      this.showUserPanel = of(false);
      this.browser = null;
    }
  }

  public showUserPanel: Observable<boolean> = of(false);



  get engagement(): Engagement {
    return this._engagement;
  }

  public EngagementState = EngagementState;
  public EngagementMode = EngagementMode;

  private _currentState: EngagementStateType = {type: 0,startState: 0}

  @Input() set currentState(v: EngagementStateType) {
      this._currentState = v;
      if (this._currentState.type === EngagementState.Post) {
        this.activeIndex = 1;
      }
  }

  get currentState():EngagementStateType {
    return this._currentState;
  }

  @Input() isPrimaryAgent: boolean;
  @Input() isPresentingAgent:boolean;
  @Input() currentUrl:URL;
  @Input() visitorCrmData: CrmStructure;
  @Input() emailState: CrmEmailStateType;
  @Input() visitor: SiteVisitor;



  @Input() checklistfields: CheckListField[];
  @Input() visitorSessionHistory: EngagementEvent[];
  @Input() visitorBrowsingHistory: BrowsingHistory[];
  @Input() privateChatEnabled: boolean;
  @Input() unreadPrivateMessages: boolean;
  @Input() privateChatMessages: TextMessage[];
  @Input() currentSection: Section;
  @Input() userGuid: string;
  @Input() privateChatAvailable: boolean;
  @Input() isElectron: boolean;

  private _isSharing: boolean = false;
  @Input() set isSharing (v:boolean) {
    this._isSharing = v;
    if (v) {
      this.activeIndex = 0;
    }
  }
  get isSharing(): boolean {
    return this._isSharing;
  }
  @Input() showBrowserBar: boolean;
  @Input() showBrowserBarControls: boolean;
  @Input() callPaused: boolean;
  @Input() domSyncActive: boolean;
  @Input() domSyncOn: Observable<boolean>;
  @Input() appViewOn: boolean;
  @Input() username: string;
  @Input() fromCustomerCommand: Observable<string>;
  @Input() currentPanelPosition:Observable<CustomerSizes>;
  @Input() showingModal:boolean;
  @Input() appViewSize: Size;
  @Input() engagementId: string;
  @Input() hideBrowserWhenSharing: boolean;

  @Output() saveCrmData = new EventEmitter<any>();
  @Output() saveOpportunity = new EventEmitter<Opportunity>();
  @Output() loadCustomer = new EventEmitter<MatchedCustomer>();
  @Output() sendPrivateMessage = new EventEmitter<string>();
  @Output() domSyncReady = new EventEmitter<any>();
  @Output() domSyncCommandMessage = new EventEmitter<string>();
  @Output() switchSharing = new EventEmitter<boolean>();
  @Output() switchAppView = new EventEmitter<boolean>();
  @Output() switchCobrowse = new EventEmitter<boolean>();
  @Output() switchSecureSharing = new EventEmitter<boolean>();
  @Input() cobrowseAdded: boolean = true;
  @Input() displayBrowseToggle: boolean = false;

  @Output() toggleCobrowseBrowser: EventEmitter<void> = new EventEmitter<void>();

  @Input() inBackground: boolean = false;
  @Input() useOverlayPanel: boolean = false;
  @Input() showCustomerHasBackgrounded: boolean = false;

  public get backEnabled(): boolean {
    if (this.browser) {
      return this.browser.backEnabled;
    } else {
      return false;
    }
  }

  public get forwardEnabled(): boolean {
    if (this.browser) {
      return this.browser.forwardEnabled;
    } else {
      return false;
    }
  }

  public hasVideo: Observable<boolean> = of(false);

  @Output()
  public crmSelected: EventEmitter<void> = new EventEmitter<void>();


  constructor(private readonly browserService: BrowserService) {

  }

  onSaveCrmData() {
    this.saveCrmData.emit();
  }

  onSaveOpportunity(opp: Opportunity) {
    this.saveOpportunity.emit(opp);
  }

  onLoadCustomer(customer: MatchedCustomer) {
    this.loadCustomer.emit(customer);
  }

  onSendPrivateChatMessage(privateMessage: string) {
    this.sendPrivateMessage.emit(privateMessage);
  }

  onDomSyncAgentReady() {
    this.domSyncReady.emit();
  }

  onDomSyncToCustomerCommand($event) {
    this.domSyncCommandMessage.emit($event);
  }

  public onToggleCobrowseBrowser() {
    this.toggleCobrowseBrowser.emit();
  }


  onAddressBarChange($event: string) {
    if (this.browser) {
      this.browser.changeUrl($event);
    }
  }

  onSwitchSharing($event: boolean) {
    this.switchSharing.emit($event);
  }

  onSwitchAppView($event: boolean) {
    this.switchAppView.emit($event);
  }

  onSwitchSecureSharing($event: boolean) {
    this.switchSecureSharing.emit($event);
  }

  onSwitchCoBrowse($event: boolean) {
    this.switchCobrowse.emit($event);
  }

  public onBack() {
    if (this.browser) {
      this.browser.back();
    }
  }

  public onForward() {
    if (this.browser) {
      this.browser.forward();
    }
  }

  public hasSharingAvailable() {
    return this.canCoShare || this.canShare || this.canCoFormFillShare;
  }

  public onTabChange($event) {
    if ($event.index === 1) {
      this.crmSelected.emit();
    }
  }

  protected readonly faChevronLeft = faChevronLeft;
  protected readonly faChevronRight = faChevronRight;
  protected readonly faHeartCircleMinus = faHeartCircleMinus;
  protected readonly faCircleUp = faCircleUp;
  protected readonly faPauseCircle = faPauseCircle;
  protected readonly faCirclePause = faCirclePause;
}
